<script>
  export default {
    name: 'ExportResults',
    props: {
      results: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      csvFields: [
        {
          key: 'productId',
          name: 'ID',
        },
        {
          key: 'productName',
          name: 'Name',
        },
        {
          key: 'price',
          name: 'Price',
        },
        {
          key: 'colors',
          name: 'Colors',
        },
        {
          key: 'categoryTree',
          name: 'Category Tree',
        },
        {
          key: 'imageUrl',
          name: 'Image',
        },
        {
          key: 'pdpUrl',
          name: 'URL',
        },
      ],
      copyTooltip: false,
      selectedCsvFields: [],
      textAreaIdList: null,
    }),
    methods: {
      async copyTextArea() {
        console.log('hi')
        await navigator.clipboard.writeText(this.textAreaIdList)
        this.copyTooltip = 'ID List copied!'
        // this.copyTooltip = null
        // setTimeout(() => (this.copyTooltip = 'ID List copied!'), 500)
        // setTimeout(() => (this.copyTooltip = null), 2000)
      },
      exportToCsv() {
        //set loading
        //save selected fields to localstorage (change created function to set them if exist)
        //prompt dl
        //close modal
        console.log('export :)')

        //create temp el to store and parse html entities
        const tempTextArea = document.createElement('textarea')

        const csvContent = []
        const heading = this.selectedCsvFields.map(s => {
          const h = this.csvFields.find(f => f.key == s)
          return h.name
        })
        csvContent.push(heading)
        // PICKUP HERE..
        this.results.forEach(r => {
            const row = []
        this.selectedCsvFields.forEach(f => {
              // const field = this.csvFields.find((field) => field.key === f)
              let val
              if (f === 'colors') {
                val = r?.colorsMarkdown?.map(c => c.id)?.join(', ') || ''
              } else {
                val = r?.[f] || '-'
              }
              tempTextArea.innerHTML = val
              const valParsed = tempTextArea.value
              row.push(valParsed)
            })
            csvContent.push(row)
            })
        // this.results.forEach(r => {
        //   const row = []
        //   r.colorsMarkdown.forEach(style => {
        //     this.selectedCsvFields.forEach(f => {
        //       // const field = this.csvFields.find((field) => field.key === f)
        //       let val
        //       if (f === 'productName' || f === 'categoryTree') {
        //         val = r?.[f] || '-'
        //       } else {
        //         val = style?.[f] || '-'
        //       }
        //       tempTextArea.innerHTML = val
        //       const valParsed = tempTextArea.value
        //       row.push(valParsed)
        //     })
        //   })
        //   csvContent.push(row)
        // })
        const csvText = ['data:text/csv;charset=utf-8,', ...csvContent.map(e => e.join(','))].join('\n')

        const encodedUri = encodeURI(csvText)
        window.open(encodedUri)
        return csvText
      },
    },
    created() {
      const IDList = this.results.map(r => r.productId).join(', ')
      // const IDList = this.results.map(r => r.colorsMarkdown.map(c => c.id)).join(', ')
      this.textAreaIdList = IDList
      this.selectedCsvFields = this.csvFields.map(f => f.key)
    },
  }
</script>
<template>
  <v-card>
    <v-btn color="primary" icon @click="$emit('close')" class="close-btn"> <v-icon color="">mdi-close</v-icon> </v-btn>

    <v-card-title class="primary--text text-h4 justify-start"> EXPORT RESULTS </v-card-title>
    <v-card-text class="d-flex flex-column align-center justify-center pb-0 pt-4">
      <v-textarea v-model="textAreaIdList" autofocus color="primary" width="100%" rows="4" hide-details label="ID List" name="textAreaIdList" outlined class="full-width">
        <template slot="prepend">
          <v-tooltip
            close-delay="1000"
            :color="copyTooltip && copyTooltip.toString().substring(0, 4) === 'Copy' ? 'primary' : 'success'"
            :open-on-click="false"
            :open-on-focus="false"
            :open-on-hover="false"
            top
            :value="copyTooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" icon v-bind="attrs" v-on="on" @click="copyTextArea" @mouseover="copyTooltip = 'Copy ID List to clipboard'" @mouseleave="copyTooltip = null">
                <v-icon color="primary">mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>
              <v-icon v-if="copyTooltip && copyTooltip.toString().substring(0, 4) != 'Copy'" color="secondary" small>mdi-check-bold</v-icon>
              {{ copyTooltip }}</span
            >
          </v-tooltip>
        </template>
      </v-textarea>
      <v-divider class="divider my-8"></v-divider>
    </v-card-text>
    <v-card-text class="pt-0">
      <h4 class="primary--text text-h6 text-left mr-auto mb-0">Save as CSV</h4>
      <p class="primary--text text-caption text-left mr-auto mb-2 mt-n2">(separated by comma when opening sheet)</p>
      <v-card outlined>
        <v-card-title class="primary--text">Fields</v-card-title>
        <v-card-text>
          <!-- <v-card-text class="d-flex flex-wrap"> -->
          <v-checkbox v-for="field in csvFields" v-model="selectedCsvFields" :key="field.key" dense hide-details :label="field.name" :value="field.key" class="mr-10"></v-checkbox>
        </v-card-text>
        <v-card-actions class="px-8">
          <v-btn block color="success" @click="exportToCsv"> <v-icon left>mdi-content-save</v-icon>SAVE </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<style scoped>
  .divider {
    width: 50%;
  }
</style>
