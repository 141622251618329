<script>
  import api from '@/services/api.js'
  export default {
    name: 'authCard',
    data: () => ({
      authCode: null,
      firstLoad: true,
      isLoading: false,
      message: null,
    }),
    computed: {
      codeInput: {
        get() {
          return this.authCode
        },
        set(v) {
          this.authCode = v.toUpperCase()
        },
      },
    },
    methods: {
      async onSubmit(codeIn = null) {
        const code = codeIn || this.authCode
        this.message = null
        this.isLoading = true
        if (!code) {
          this.message = 'Authorization code required.'
          this.isLoading = false
          return
        }
        if (code === '143TJ') {
          this.message = 'Aww... I love you too &#128156; <br /> But that is not the code.'
          this.isLoading = false
          return
        }
        if (code === '143LISA') {
          this.message = '&#128536; <br /> Kisses! That is not the code.'
          this.isLoading = false
          return
        }
        if (code === '143BENN') {
          this.message = '&#129430; <br /> Roar! That is not the code.'
          this.isLoading = false
          return
        }
        // this.message = this.message ? null : 'Test message here'
        const resp = await api.get('auth', [{ key: 'code', value: code }]).catch(e => {
          console.log(e)
          this.message = 'Error connecting to backend service..'
          this.$emit('isAuth', false)
          this.isLoading = false
          return false
        })
        const isAuth = resp?.status === 'ok'
        if (isAuth) {
          localStorage.setItem('code', code)
        } else {
          this.message = 'Invalid code entered.'
        }
        console.log(isAuth)
        this.$emit('isAuth', isAuth)
        this.isLoading = false
        return isAuth
      },
    },
    async created() {
      const code = localStorage.getItem('code')
      if (code) {
        const isAuth = await this.onSubmit(code)
        this.$emit('isAuth', isAuth)
      }
      this.firstLoad = false
    },
  }
</script>
<template>
  <v-sheet width="100%" class="d-flex justify-center">
    <v-card v-show="!firstLoad" width="100%" max-width="400">
      <v-card-title class="primary--text justify-center text-h4">WELCOME</v-card-title>
      <v-card-text class="card-text d-flex flex-column justify-space-around mt-4">
        Please confirm access.
        <v-text-field v-model="codeInput" color="primary" id="authinput" outlined type="text" @keyup.enter="onSubmit(codeInput)" class="mx-8 text-center"></v-text-field>
        <p v-if="message" v-html="message" class="error--text"></p>
      </v-card-text>
      <v-card-actions class="px-4 pb-4">
        <v-btn block color="primary" :loading="isLoading" @click="onSubmit(codeInput)"> submit </v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<style scoped>
  .card-text {
    height: 170px;
  }

  #authinput {
    text-align: center;
  }

  >>> .v-input input {
    text-align: center;
    color: var(--primary-text);
    font-size: 2rem;
  }
</style>
