<script>
  export default {
    name: 'product-image',
    props: {
      img: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      dimmensions: {
        height: '',
        width: '',
      },
    }),
    computed: {},
    methods: {},
    created() {
      const DEFAULT_HEIGHT = 'auto'
      const DEFAULT_WIDTH = '600px'
      const splitParams = this.img.split('?')?.[1]
      if (splitParams) {
        const splitDims = splitParams.split('&')
        const height =
          splitDims?.[0].substring(0, 4) === 'hei=' ? splitDims?.[0]?.substring(4) : splitDims?.[1].substring(0, 4) === 'hei=' ? splitDims?.[1]?.substring(4) : DEFAULT_HEIGHT
        const width =
          splitDims?.[0].substring(0, 4) === 'wid=' ? splitDims?.[0]?.substring(4) : splitDims?.[1].substring(0, 4) === 'wid=' ? splitDims?.[1]?.substring(4) : DEFAULT_WIDTH
        this.dimmensions.height = DEFAULT_HEIGHT === height ? height : `${height}px`
        this.dimmensions.width = DEFAULT_WIDTH === width ? width : `${width}px`
      }
    },
  }
</script>

<template>
  <v-img
    contain
    position="center center"
    :src="img"
    :height="'auto'"
    :max-height="dimmensions.height"
    :max-width="dimmensions.width"
    width="100%"
    @click="$emit('close')"
    class="rounded"
  ></v-img>
</template>
