<script>
// @ is an alias to /src
import Auth from '@/components/Auth.vue'
import Home from '@/components/Home.vue'

export default {
  name: 'HomeView',
  components: {
    Auth,
    Home,
  },
  data: () => ({
    isAuth: null,
  }),
}
</script>
<template>
  <component
    :is="isAuth ? 'Home' : 'Auth'"
    @isAuth="isAuth = $event"
    @snackbar="$emit('snackbar', $event)"
  />
</template>

<style scoped></style>
