<script>
  import ProductItem from '@/components/product-item.vue'
  export default {
    name: 'product-details',
    props: {
      product: {
        type: Object,
        required: true,
      },
    },
    components: {
      ProductItem,
    },
  }
</script>

<template>
  <v-card>
    <v-btn color="primary" icon @click="$emit('close')" class="close-btn"> <v-icon color="">mdi-close</v-icon> </v-btn>

    <v-card-title class="primary--text text-h4 justify-center"> PRODUCT DETAILS</v-card-title>
    <!-- <p v-html="product.name" class="text-left font-weight-bold primary secondary--text pl-10 py-4" /> -->
    <v-list class="product-item mx-1">
      <product-item :product="product" :showActions="false"></product-item>
    </v-list>
    <v-card-text style="height: 50vh">
      <v-list max-width="600">
        <template v-for="detail in Object.keys(product).sort((a, b) => a.toString().localeCompare(b.toString()))">
          <v-list-item :key="detail" class="text-left">
            <v-list-item-content class="py-0">
              <v-list-item-title v-html="detail" class="primary--text font-weight-bold"></v-list-item-title>
              <v-list-item-subtitle v-html="product[detail] || '-'" class="detail-subtitle"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="detail + 'd'" class="my-2"></v-divider>
        </template>
      </v-list>
    </v-card-text>
    <v-card-actions>&nbsp; </v-card-actions>
  </v-card>
</template>

<style scoped>
  .product-item {
    border: solid 5px var(--primary-color);
  }
</style>
