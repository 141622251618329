<script>
  export default {
    name: 'product-item',
    props: {
      product: {
        type: Object,
        required: true,
      },
      showActions: {
        type: Boolean,
        default: () => true,
      },
    },
    computed: {},
    methods: {
      onImgLoad() {
        // console.log(e)
      },
    },
    created() {},
  }
</script>

<template>
  <v-list-item class="text-left">
    <v-list-item-avatar size="50" @click="$emit('showImage', product)" class="pointer">
      <v-img :src="product.imageUrl" @load="onImgLoad"></v-img>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="text-h6  primary--text font-weight-bold" >
        <a v-html="product.productName" :href="product.pdpUrl" rel="noopener" target="_blank" :title="product.productName" style="text-decoration: none;"></a>
      </v-list-item-title>
      <v-list-item-subtitle class="mb-4">
        <v-chip color="primary" label small class=" font-weight-bold pa-2"> <span class="cursor-text">{{ product.productId }} </span></v-chip>

      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="product?.colors?.length" class="d-flex flex-wrap mt-4">
        <v-menu v-for="(style, k) in product.colors" :key="style.id" :close-on-content-click="false" :close-delay="200" :open-delay="300" open-on-hover offset-y top>
          <template v-slot:activator="{ on, attrs }">
            <v-sheet v-bind="attrs" v-on="on" class="d-flex align-center mr-2">
              <a href="#" class="text-body-1 font-weight-bold mr-1">{{ style.id }} </a>
              <span class="text-body-2 font-weight-bold success--text"> {{ style.priceDisplayed }} </span>{{ k === product.colors.length - 1 ? '' : ', ' }}
            </v-sheet>
          </template>
          <v-card  :href="style.pdpUrl" target="_blank" class="rounded-lg">
            <v-card-text  class="d-flex align-center justify-start text-body-1 font-weight-bold primary--text pa-2">
              <v-avatar size="35" class="mr-2"><img height="40" width="40" :src="`https://www.madewell.com/images/${style.masterId}_${style.id}_sw?wid=40&hei=40`" /></v-avatar>
              <p class="mb-0 mr-2"> {{ style.name }}</p> <v-icon color="primary"  small>mdi-open-in-new</v-icon>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="product?.colorsMarkdown?.length" class="d-flex">
        <v-menu v-for="(style, k) in product.colorsMarkdown" :key="style.id" :close-on-content-click="false" :close-delay="200" :open-delay="300" open-on-hover offset-y top>
          <template v-slot:activator="{ on, attrs }">
            <v-sheet v-bind="attrs" v-on="on" class="d-flex align-center mr-2">
              <a href="#" class="text-body-1 font-weight-bold mr-1">{{ style.id }} </a>
              <span class="text-body-2 font-weight-bold warning--text"> {{ style.priceDisplayed }} </span>{{ k === product.colorsMarkdown.length - 1 ? '' : ', ' }}
            </v-sheet>
          </template>
          <v-card  :href="style.pdpUrl" target="_blank" class="rounded-lg">
            <v-card-text  class="d-flex align-center justify-start text-body-1 font-weight-bold primary--text pa-2">
              <v-avatar size="35" class="mr-2"><img height="40" width="40" :src="`https://www.madewell.com/images/${style.masterId}_${style.id}_sw?wid=40&hei=40`" /></v-avatar>
              <p class="mb-0 mr-2"> {{ style.name }}</p> <v-icon color="primary"  small>mdi-open-in-new</v-icon>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="showActions" class="d-flex flex-row ma-0 mt-auto pb-2">
      <v-tooltip color="primary" outlined top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="$emit('showDetails', product)">
            <v-icon color="primary">mdi-format-list-bulleted</v-icon>
          </v-btn>
        </template>
        <span class="secondary--text">Show Details</span>
      </v-tooltip>

      <v-tooltip color="primary" outlined top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="$emit('showImage', product)">
            <v-icon color="primary">mdi-image</v-icon>
          </v-btn>
        </template>
        <span class="secondary--text">View Image</span>
      </v-tooltip>

      <v-tooltip color="primary" outlined top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" :href="product.pdpUrl" rel="noopener" target="_blank">
            <v-icon color="primary">mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span class="secondary--text">Open product page</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<style scoped>
img {
  max-width: calc(100% - 2px);
  height: auto;
  transform: translate(4.5%,4.5%);
}
</style>
